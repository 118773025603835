/* ---------------COLORS------------------------*/
/*** New colors (Jun 2022) ****/
// We'll use the convention $purpose_color for the new design system to avoid conflicts with old colors of the same name (e.g. old teal vs new teal)
// L&F Oatmeal
$ui_oatmeal1: #FBFAF9;
$ui_oatmeal2: #F5F2EE;
$ui_oatmeal3: #DFDBD6;
$ui_oatmeal4: #8F8A85;
$ui_oatmeal5: #4F4D4A;
$ui_oatmeal6: #1A1918;

// L&F Teal - Primary
$primary_teal1: #E7F8F8;
$primary_teal2: #85CCD5;
$primary_teal3: #3BA5B5;
$primary_teal4: #017A8F; 
$primary_teal5: #09586E; 
$primary_teal6: #073345; 

// L&F Peach - Secondary
$secondary_peach1: #FFF3F0;
$secondary_peach2: #FCD0C2; 
$secondary_peach3: #FCB097; 
$secondary_peach4: #D96D4A; 
$secondary_peach5: #B84C33; 
$secondary_peach6: #8F3424; 

// L&F Green - Success
$success_green1: #EDF5DC;
$success_green2: #C2E599; 
$success_green3: #8CC94B; 
$success_green4: #5F9C22; 
$success_green5: #357806; 
$success_green6: #00520A; 

// L&F Gold - Warning
$warning_gold1: #FAF3D6;
$warning_gold2: #FFD793; 
$warning_gold3: #FFB84D; 
$warning_gold4: #D67200; 
$warning_gold5: #B55112; 
$warning_gold6: #8C351C;

// L&F Red - Error
$error_red1: #FAE9EA;
$error_red2: #FFBFC0; 
$error_red3: #FFA1A4; 
$error_red4: #E0656F; 
$error_red5: #BA455B; 
$error_red6: #942944; 
/**** Old colors (Jan 2020) ****/
// Teal - Primary
$teal1: #d6eaed;
$teal2: #82c1ca;
$teal3: #4ca9b7;
$teal4: #008599;
$teal5: #007081;
$teal6: #004f5b;

// Navy - Secondary
$navy1: #d6e3eb;
$navy2: #80a2b4;
$navy3: #5d7b8a;
$navy4: #35596d;
$navy5: #22485c;
$navy6: #123243;

// Purple - Tertiary
$purple1: #f2dce8;
$purple2: #e2b1cc;
$purple3: #be7ca0;
$purple4: #ae5b89;
$purple5: #9e4275;
$purple6: #852e5e; // TODO: double check

// Green - Success
$green1: #edf4f1;
$green2: #b9d5c9;
$green3: #84b4a0;
$green4: #429672;
$green5: #2a7a58;
$green6: #127049;

// Orange - Warning
$orange1: #f9f1e5;
$orange2: #eac89a;
$orange3: #d99e4d;
$orange4: #ca7602;
$orange5: #ad5700;
$orange6: #954c00;
$orange7: #942d0f;
$orange8: #ffd793;
$orange9: #c25713;

// Red - Error
$red1: #f9ebeb;
$red2: #eaafaf;
$red3: #e08888;
$red4: #c64e4e;
$red5: #b63131;
$red6: #982a2a;
$red7: #942944;
$red8: #ffbfc0;

// Charcoal
$charcoal1: #f1f3f5;
$charcoal2: #d1d4d7;
$charcoal3: #babec3;
$charcoal4: #878d97;
$charcoal5: #687078;
$charcoal6: #343a40;

// Marine
$marine: #133D54;
$marine400: #7E9FB0;
$marine100: #E9EFF2;

// Aqua
$aqua: #368D9B;
$aqua400: #89BEC6;
$aqua100: #E0EEF0;

// Sage
$sage: #36745A;
$sage400: #9ABFAF;
$sage100: #E8F2EE;

// Sahara
$sahara: #B68952;
$sahara400: #D2B898;
$sahara100: #F7F0E7;

// Persimmon
$persimmon: #B55C53;
$persimmon400: #D1928C;
$persimmon100: #F6E9E8;

// Plum
$plum: #852E5E;
$plum400: #BD7CA0;
$plum100: #E7D5DF;

// Yosemite
$yosemite: #2F3335;

// Teton
$teton: #B6B8BA;


$educated-suggestion: $purple1;
$educated-suggestion-text: $purple6;

// Core palette - Some of these are redundant now. Leaving until our color system is locked in
$brand: $navy6;
$primary-action: $teal5;
$secondary-action: $navy4;

//Core palette light - Will become disabled states
$brand-light: $navy3;
$primary-light: $teal3;
$secondary-light: $navy3;
$secondary-light-50: $navy2; // TODO: What does this color map to?

//Core palette disabled - will be deprecated in favor of palette light
$primary-disabled: $teal1;
$secondary-disabled: $navy1;

//Core palette dark - will become new hover states
$brand-dark: $navy6;
$primary-dark: $teal6;
$secondary-dark: $navy6;

//Core palette highlight
$primary-highlight: $teal3;
$secondary-highlight: $navy3;

//Gradients
@mixin linear-gradient-1($angle: 135deg, $start: 0%, $end: 100%) {
  background: linear-gradient($angle, #0092a5 $start, #86c779 $end);
}

//Supplemental palette
$success: $green4;
$alert: $red4;
$alert-dark: $red5;
$gentle-notification: $navy4;
$highlight: $orange4;
$success-dark: $green5;
$soft-purple: $purple4;
$dark_purple: $purple5;
$warning: $orange4;
$warning-dark: $orange5;
$alert-warning: #FFF1D6;

//Text, Fills, Lines, Borders
$soft-black: $charcoal6;
$dark-gray: $charcoal6;
$semi-dark-gray: $charcoal5;
$medium-gray: $charcoal4;
$light-gray-100: $charcoal3;
$light-gray-70: $charcoal2;
$light-gray-40: $charcoal2;
$light-gray-10: $charcoal1;
$white: #ffffff;

:export {
  x_brand: $brand;
  x_primary_action: $primary-action;
  x_secondary_action: $secondary-action;
  x_brand_light: $brand-light;
  x_primary_light: $primary-light;
  x_secondary_light: $secondary-light;
  x_secondary_light_50: $secondary-light-50;
  x_brand_dark: $brand-dark;
  x_primary_dark: $primary-dark;
  x_secondary_dark: $secondary-dark;
  x_primary_highlight: $primary-highlight;
  x_secondary_highlight: $secondary-highlight;
  x_primary_teal4: $primary_teal4;
  x_success: $success;
  x_alert: $alert;
  x_alert_dark: $alert-dark;
  x_alert_warning: $alert-warning;
  x_gentle_notification: $gentle-notification;
  x_highlight: $highlight;
  x_success_dark: $success-dark;
  x_secondary_light_50: $secondary-light-50;
  x_soft_purple: $soft-purple;
  x_soft_black: $soft-black;
  x_dark_gray: $dark-gray;
  x_semi_dark_gray: $semi-dark-gray;
  x_medium_gray: $medium-gray;
  x_light_gray_100: $light-gray-100;
  x_light_gray_70: $light-gray-70;
  x_light_gray_40: $light-gray-40;
  x_light_gray_10: $light-gray-10;
  x_white: $white;
  x_navy1: $navy1;
  x_navy3: $navy3;
  x_navy4: $navy4;
  x_navy5: $navy5;
  x_navy6: $navy6;
  x_educated_suggestion: $educated-suggestion;
  x_educated_suggestion_text: $educated-suggestion-text;
  x_dark_purple: $dark_purple;
  x_red1: $red1;
  x_red2: $red2;
  x_red4: $red4;
  x_red5: $red5;
  x_red6: $red6;
  x_warning: $warning;
  x_warning_dark: $warning_dark;
  x_teal1: $teal1;
  x_teal3: $teal3;
  x_teal5: $teal5;
  x_orange1: $orange1;
  x_orange3: $orange3;
  x_orange5: $orange5;
  x_orange8: $orange8;
  x_green1: $green1;
  x_green4: $green4;
  x_marine: $marine;
  x_marine400: $marine400;
  x_marine100: $marine100;
  x_aqua: $aqua;
  x_aqua400: $aqua400;
  x_aqua100: $aqua100;
  x_sage: $sage;
  x_sage400: $sage400;
  x_sage100: $sage100;
  x_sahara: $sahara;
  x_sahara400: $sahara400;
  x_sahara100: $sahara100;
  x_persimmon: $persimmon;
  x_persimmon400: $persimmon400;
  x_persimmon100: $persimmon100;
  x_plum: $plum;
  x_plum400: $plum400;
  x_plum100: $plum100;
  x_yosemite: $yosemite;
  x_teton: $teton;
  x_purple1: $purple1;
  x_purple5: $purple5;
  ui_oatmeal_1: $ui_oatmeal1;
  ui_oatmeal_2: $ui_oatmeal2;
  ui_oatmeal_3: $ui_oatmeal3;
  ui_oatmeal_4: $ui_oatmeal4;
  ui_oatmeal_5: $ui_oatmeal5;
  ui_oatmeal_6: $ui_oatmeal6;
  secondary_peach1: $secondary_peach1;
  secondary_peach2: $secondary_peach2; 
  secondary_peach3: $secondary_peach3; 
  secondary_peach4: $secondary_peach4; 
  secondary_peach5: $secondary_peach5; 
  secondary_peach6: $secondary_peach6; 
  error_red5: $error_red5;
}

/* ---------------BREAKPOINTS------------------------*/
$x_tablet_min_width: 768;
$x_laptop_min_width: 992;
$x_laptop_container_width_px: 864px;
$x_desktop_container_width_px: 944px;
$x_large_desktop_container_width: 1440;
$x_desktop_margins_px: 248px;

@mixin breakpoint($point) {
  @if $point ==mobileonly {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $point ==mobile-xs {
    @media (min-width: 374px) {
      @content;
    }
  }

  @else if $point ==mobile-s {
    @media (min-width: 460px) {
      @content;
    }
  }

  @else if $point ==mobile-m {
    @media (min-width: 575px) {
      @content;
    }
  }

  @else if $point ==mobile-l {
    @media (min-width: 640px) {
      @content;
    }
  }

  @else if $point ==tablet {
    @media (min-width: $x_tablet_min_width + 'px') {
      @content;
    }
  }

  @else if $point ==laptop {
    @media (min-width: $x_laptop_min_width + 'px') {
      @content;
    }
  }

  @else if $point ==desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @else if $point ==desktop-l {
    @media (min-width: $x_large_desktop_container_width + 'px') {
      @content;
    }
  }
}

:export {
  x_tablet_min_width: $x_tablet_min_width;
  x_laptop_min_width: $x_laptop_min_width;
  x_laptop_container_width_px: $x_laptop_container_width_px;
  x_desktop_container_width_px: $x_desktop_container_width_px;
  x_large_desktop_container_width: $x_large_desktop_container_width;
  x_desktop_margins_px: $x_desktop_margins_px;
}
/* ---------------TYPOGRAPHY------------------------*/

//Font weights
$regular: 400;
$mediumWeight: 500;
$semibold: 700; // Deprecating
$bold: 700;
$black: 800;

@mixin moderat {
  @font-face {
    font-family: 'moderat';
    font-weight: $black;
    font-style: normal;
    src: url('../lib/fonts/Moderat-Black.woff2') format('woff2');
  }
  @font-face {
    font-family: 'moderat';
    font-weight: $bold;
    font-style: normal;
    src: url('../lib/fonts/Moderat-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'moderat';
    font-weight: $mediumWeight;
    font-style: normal;
    src: url('../lib/fonts/Moderat-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'moderat';
    font-weight: $regular;
    font-style: normal;
    src: url('../lib/fonts/Moderat-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'moderat';
    font-weight: $regular;
    font-style: italic;
    src: url('../lib/fonts/Moderat-Regular-Italic.woff2') format('woff2');
  }
}

// Typefaces and fallbacks
@mixin primary-font {
  font-family: 'moderat', sans-serif;
  line-height: normal;
  letter-spacing: normal;
  font-weight: $regular;
}

// DEPRECATING
@mixin display-font {
  font-family: 'moderat', sans-serif;
  line-height: normal;
  letter-spacing: normal;
  font-weight: $bold;
}

@mixin mono-font {
  font-family: 'PT Mono', Courier, monospace;
  line-height: normal;
  letter-spacing: 1px;
}

// 2023 Type styles

@mixin default-link($background: 'lightBg') {
  cursor: pointer;
  font-weight: $bold;
  color: $primary-teal4;
  text-decoration: underline;
  border-radius: 4px;
  border: 1.5px solid transparent;

  &:hover {
    color: $primary-teal4;
  }

  &:active, &:focus {
    border: 1.5px solid $primary_teal4;
    color: $primary_teal4;
    outline: none;
    box-shadow: none;
    background: none;
  }

  &:focus:not(:focus-visible) {
    background: none;
    border: none;
    box-shadow: none;
  }

  @if $background == 'darkBg' {
    color: $white;

    &:hover {
      color: $white;
    }

    &:active, &:focus {
      color: $white;
      border: 1.5px solid $primary_teal2;
    }
  }
}


// 2022 Type styles

// Display
@mixin largeHeadline($background: 'lightBg') {
  @include primary-font;
  font-weight: $bold;
  font-size: 56px;
  line-height: 66px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin mediumHeadline($background: 'lightBg') {
  @include primary-font;
  font-weight: $bold;
  font-size: 48px;
  line-height: 54px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin smallHeadline($background: 'lightBg') {
  @include primary-font;
  font-weight: $bold;
  font-size: 34px;
  line-height: 38px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin largeSubhead($background: 'lightBg') {
  @include primary-font;
  font-weight: $mediumWeight;
  font-size: 28px;
  line-height: 34px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin mediumSubhead($background: 'lightBg') {
  @include primary-font;
  font-weight: $mediumWeight;
  font-size: 22px;
  line-height: 28px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin smallSubhead($background: 'lightBg') {
  @include primary-font;
  font-weight: $mediumWeight;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin extraSmallSubhead($background: 'lightBg') {
  @include primary-font;
  font-weight: $bold;
  font-size: 16px;
  line-height: 22px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin eyebrowHeadline($background: 'lightBg') {
  @include primary-font;
  font-weight: $bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

// Body text
@mixin largeBody($background: 'lightBg') {
  @include primary-font;
  font-weight: $regular;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }

  a, .link {
    @include link($background)
  }
}

@mixin defaultBody($background: 'lightBg') {
  @include primary-font;
  font-weight: $regular;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }

  a, .link {
    @include link($background)
  }
}

@mixin smallBody($background: 'lightBg') {
  @include primary-font;
  font-weight: $regular;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

@mixin captionBody($background: 'lightBg') {
  @include primary-font;
  font-weight: $regular;
  font-size: 12px;
  line-height: 18px;
  text-transform: none;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $soft-black;
  }
}

// Map new type styles to old
@mixin header1($background: 'lightBg') {
  @include mediumHeadline($background);
}

@mixin header1-xl($background: 'lightBg') {
  @include largeHeadline($background);
}

@mixin header2($background: 'lightBg') {
  @include smallHeadline($background);
}

@mixin header3($background: 'lightBg') {
  @include mediumSubhead($background);
}

@mixin header4($background: 'lightBg') {
  @include smallSubhead($background);
}

@mixin header4-quiet($background: 'lightBg') {
  @include header4;
  @if $background == 'darkBg' {
    color: $brand-light;
  } @else {
    color: $medium-gray;
  }
}

@mixin header4-highlight($background: 'lightBg') {
  @include header4;
  @if $background == 'darkBg' {
    color: $brand-light;
  } @else {
    color: $success;
  }
}

@mixin header5($background: 'lightBg') {
  @include eyebrowHeadline($background);
}

@mixin paragraph-large($background: 'lightBg') {
  @include largeBody($background);
}

@mixin paragraph-large-strong($background: 'lightBg') {
  @include largeBody($background);
}

@mixin paragraph-large-highlight($background: 'lightBg') {
  @include paragraph-large;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $brand;
  }
}

@mixin paragraph-large-quiet($background: 'lightBg') {
  @include paragraph-large;
  @if $background == 'darkBg' {
    color: $brand-light;
  } @else {
    color: $medium-gray;
  }
}

@mixin paragraph($background: 'lightBg') {
  @include defaultBody($background);
}

@mixin paragraph-strong($background: 'lightBg') {
  @include defaultBody($background);
}

@mixin paragraph-highlight($background: 'lightBg') {
  @include paragraph;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $brand;
  }
}

@mixin paragraph-quiet($background: 'lightBg') {
  @include paragraph;
  @if $background == 'darkBg' {
    color: $brand-light;
  } @else {
    color: $medium-gray;
  }
}

@mixin paragraph-small($background: 'lightBg') {
  @include smallBody($background)
}

@mixin paragraph-small-strong($background: 'lightBg') {
  @include smallBody($background)
}

@mixin paragraph-small-quiet($background: 'lightBg') {
  @include paragraph-small;
  @if $background == 'darkBg' {
    color: $brand-light;
  } @else {
    color: $medium-gray;
  }
}

@mixin paragraph-extra-small($background: 'lightBg') {
  @include captionBody($background)
}

@mixin paragraph-extra-small-strong($background: 'lightBg') {
  @include captionBody($background)
}

@mixin paragraph-extra-small-quiet($background: 'lightBg') {
  @include paragraph-extra-small;
  @if $background == 'darkBg' {
    color: $brand-light;
  } @else {
    color: $medium-gray;
  }
}

@mixin footnote($background: 'lightBg') {
  @include paragraph-extra-small($background)
}

@mixin hint-text { // TODO: where is this used
  @include primary-font;
  font-weight: $regular;
  font-size: 15px;
  color: $light-gray-100;
  line-height: 24px;
}

@mixin quote($background: 'lightBg') { // TODO: where is this used
  @include primary-font;
  font-size: 18px;
  line-height: 26px;
  font-weight: $bold;
  font-style: italic;
  @if $background == 'darkBg' {
    color: $white;
  } @else {
    color: $dark-gray;
  }
}

@mixin quote-large { // TODO: where is this used
  @include quote;
  font-size: 24px;
  line-height: 34px;
}

/* 
Previous link style use default-link going forward.
New Link Changes:
  - color light background - primary teal 
  - underline by default
  - no hover state style
  - active and focus state have 1.5px border
  - removed background fails WCAG color contrast
  - removed box-shadow
*/
@mixin link($background: 'lightBg') {
  cursor: pointer;
  font-weight: $bold;
  color: $primary-action;
  text-decoration: none;
  border-radius: 4px;

  &:active, &:hover {
    color: $primary-dark;
    text-decoration: underline;
  }

  @include focus-style;

  @if $background == 'darkBg' {
    color: $white;
    &:active, &:hover {
      color: $white;
    }
    @include focus-style(transparent);
  }
}

@mixin strong {
  font-weight: $bold;
  color: $soft-black;
}

/* ---------------LAYOUT------------------------*/

$teenie: 4px;
$tiny: 8px;
$small: 16px;
$standard: 24px;
$medium: 32px;
$big: 40px;
$huge: 48px;
$jumbo: 64px;

@mixin siteHtmlBodyStyles {
  background: inherit;
}

@mixin base-white-card($shadow: false) {
  $shadowStyle: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  background-color: $white;
  box-shadow: 0 0 0 1px $light-gray-70;
  border-radius: 4px;
  overflow: hidden; // this is needed to inforce rounded corners on inner items that may have backgrounds. to allow overflow (for inner dropdown elements), override this properly locally and fix inner corners manually
  @if ($shadow) {
    box-shadow: $shadowStyle;
  }
  &.shadow {
    box-shadow: $shadowStyle;
  }
}

@mixin new-white-card {
  background-color: $white;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden; // this is needed to inforce rounded corners on inner items that may have backgrounds. to allow overflow (for inner dropdown elements), override this properly locally and fix inner corners manually
}

:export {
  x_teenie: $teenie;
  x_tiny: $tiny;
  x_small: $small;
  x_standard: $standard;
  x_medium: $medium;
  x_big: $big;
  x_huge: $huge;
  x_jumbo: $jumbo;
}

/* BUTTONS ------------------------------
-----------------------------------------*/

/* ---------------BASE-------------------*/
@mixin base-btn {
  display: inline-flex;
  position: relative;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  @include primary-font;
  font-weight: $regular;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  &:active,
  &.active {
    background-image: none;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
}

// Accepted $style values: 'outlined', 'textOnly', 'unstyled', ''
// Accepted $align values: 'left', 'right', ''
@mixin configure-button($primary: false, $style: '', $small: false, $align: '') {
  @include base-btn;

  $main-color: $navy5;
  $hover-color: $secondary-dark;
  $focus-color: $navy1;
  $disabled-color: $light-gray-70;
  $text-decoration: none;
  $hover-text-decoration: none;

  @if ($primary) {
    $main-color: $primary_action;
    $hover-color: $primary-dark;
    $focus-color: $teal1;
    $disabled-color: $light-gray-100;
  }

  $height: 48px;
  $horizontal-padding: 24px;
  $min-width: 125px;
  $icon-margin: 12px;
  $loading-position-right: -50px;
  $loading-position-top: 11px;
  $border-radius: 4px;

  @if ($small) {
    @include paragraph-strong;
    $height: 40px;
    $min-width: 100px;
    $icon-margin: 6px;
    $horizontal-padding: 16px;
    $loading-position-right: -40px;
    $loading-position-top: 9px;
  } @else {
    @include paragraph-strong;
  }

  // Normal
  $text: $white;
  $background: $main-color;
  $stroke: $main-color;
  $stroke-width: 1px;
  //Hover
  $text-hover: $white;
  $background-hover: $hover-color;
  $stroke-hover: $hover-color;
  //Focus
  $text-focus: $white;
  $background-focus: $hover-color;
  $stroke-focus: $hover-color;
  // disabled
  $text-disabled: $soft-black;
  $background-disabled: $disabled-color;
  $stroke-disabled: $disabled-color;

  @if ($style == 'outlined') {
    // Normal
    $text: $main-color;
    $background: transparent;
    $stroke: $main-color;
    //Hover
    $text-hover: $hover-color;
    $background-hover: transparent;
    $stroke-hover: $hover-color;
    //Focus
    $text-focus: $main-color;
    $background-focus: $focus-color;
    $stroke-focus: $main-color;
    // disabled
    $text-disabled: $soft-black;
    $background-disabled: transparent;
    $stroke-disabled: $disabled-color;
  }

  @if ($style == 'textOnly') {
    // Normal
    $text: $main-color;
    $background: transparent;
    $stroke: transparent;
    //Hover
    $text-hover: $hover-color;
    $background-hover: transparent;
    $stroke-hover: transparent;
    $hover-text-decoration: underline;
    //Focus
    $text-focus: $main-color;
    $background-focus: $focus-color;
    $stroke-focus: transparent;
    // disabled
    $text-disabled: $disabled-color;
    $background-disabled: transparent;
    $stroke-disabled: transparent;
  }

  @if ($style == 'elliptic') {
    $min-width: 64px;
    $height: 64px;
    $border-radius: 50%;
    $icon-margin: 0;
    $border: none;
  }

  @if ($align == 'left') {
    margin-left: $horizontal-padding * -1;
  }
  @if ($align == 'right') {
    margin-right: $horizontal-padding * -1;
  }

  height: $height;
  padding: 0px $horizontal-padding;
  min-width: $min-width;
  color: $text;
  background-color: $background;
  border: $stroke-width solid $stroke;
  border-radius: $border-radius;
  text-decoration: $text-decoration;

  &:hover,
  &:active {
    color: $text-hover;
    background: $background-hover;
    border-color: $stroke-hover;
    text-decoration: $hover-text-decoration;

    //Override bootstrap
    &:disabled,
    &.disabled {
      color: $text-disabled;
      background: $background-disabled;
      border: 1px solid $stroke-disabled;
    }
  }

  &:focus {
    color: $text-focus;
    background: $background-focus;
    border-color: $stroke-focus;
    @include focus-boxshadow;
    text-decoration: $hover-text-decoration;
  }

  // override focus styles when focus-visible is supported
  // this sets the button back to it's non focus styles
  &:focus:not(:focus-visible) {
    box-shadow: none;
    background-color: $background;
    text-decoration: $text-decoration;
    border-color: $stroke;
    color: $text;

    &:hover,
    &:active {
      color: $text-hover;
      background: $background-hover;
      border-color: $stroke-hover;
      text-decoration: $hover-text-decoration;

      //Override bootstrap
      &:disabled,
      &.disabled {
        color: $text-disabled;
        background: $background-disabled;
        border: 1px solid $stroke-disabled;
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $text-disabled;
    background: $background-disabled;
    border: 1px solid $stroke-disabled;
  }

  // Styles for embedded icons
  svg {
    margin-right: $icon-margin;
    path {
      fill: currentColor;
    }
  }

  .loading-container {
    cursor: auto;
    position: absolute;
    right: $loading-position-right;
    top: $loading-position-top;

    // Overrides icon styles
    svg {
      margin-right: 0;
      path {
        fill: unset;
      }
    }
  }

  &.loader-within {
    .loading-container {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      // Changes loading indicator color
      @if ($style == 'outlined') {
        svg linearGradient stop {
          stop-color: $light-gray-70;
        }
      } @else {
        svg linearGradient stop {
          stop-color: $white;
        }
      }
    }

    // Hides text when loadinging within
    &.loading {
      color: $background-disabled;
    }
  }

  //Aligns text
  &.right {
    margin-right: -$horizontal-padding;
  }

  &.left {
    margin-left: -$horizontal-padding;
  }
}

// DEPRECATING. Use configure-button directly
@mixin primary-button {
  @include configure-button($primary: true, $small: false);
}

// DEPRECATING. Use configure-button directly
@mixin secondary-button {
  @include configure-button($primary: false, $small: false);
}

@mixin single-select-button {
  @include base-btn;
  @include paragraph;
  height: 56px;
  border-radius: 26px;
  text-align: center;
  border: 1px solid $light-gray-10;
  background-color: $light-gray-10;
  padding: 0 16px;

  &:hover {
    color: $primary-action;
    background-color: $white;
    border-color: $primary-action;
  }

  &:focus {
    @include focus-boxshadow;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}

@mixin single-selected {
  // Use this mixin when adding a selected css class to the button.
  color: $white;
  background-color: $primary-action;
  border-color: $primary-action;

  &:hover {
    //disable hover state when button is selected
    color: $white;
    background-color: $primary-action;
    border-color: $primary-action;
  }
}

@mixin single-select-button-selected {
  // Use this mixin when switching between a selected css class and non-selected.
  @include single-select-button;
  @include single-selected;
}

@mixin segment-selected {
  // Use this mixin when adding a selected css class to the button.
  color: $white;
  border-color: $primary-action;
  background-color: $primary-action;
}

@mixin dropdown-button {
  margin: 0 12px;

  &.primary {
    @include configure-button($primary: true, $small: false);
  }

  &.primary-line {
    @include configure-button($primary: true, $style: 'outlined', $small: false);

    &.open {
      background-color: $primary_teal4 !important;
      border-color: $primary_teal4 !important;
      color: $white !important;
    }
  }

  &.secondary {
    @include configure-button($primary: false, $style: 'outlined', $small: false);
  }

  &.transparent {
    @include configure-button($primary: false, $small: false);
    background: transparent !important;
    border-color: transparent !important;
    color: $white !important;
  }

  &.inline {
    display: inline-flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    user-select: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;

    svg,
    & path {
      fill: currentColor;
    }

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:focus,
    &.focus {
      outline: 0;
    }

    &:active,
    &.active {
      background-image: none;
    }

    &.disabled,
    &:disabled {
      cursor: not-allowed;
    }
  }

  &.ghost {
    @include configure-button($primary: false, $style: 'outlined', $small: false);
    @include ghost;
  }

  svg:first-of-type {
    &:global(#envelope) {
      top: 16px;
    }
  }

  @include breakpoint(mobileonly) {
    width: 100%;
    margin: 0;
  }
}

@mixin dropdown-menu {
  margin: 8px 0 0 0;
  padding: 24px 0;
  background-color: $white;
  border: none;
  box-shadow: 0 2px 10px 0 rgba(#000, 0.1);
  overflow: hidden;
  display: none;
  min-width: 100%;
}

@mixin dropdown-list-header {
  @include paragraph-small-strong;
  color: $medium-gray;
  padding: 0 24px;
  height: 30px;
  display: flex;
  align-items: flex-end;
}

@mixin dropdown-list-item {
  @include paragraph-large;
  //line-height: 36px;
  display: flex;
  align-items: center;
  color: $secondary-action;
  position: relative;
  list-style: none;
  cursor: pointer;
  padding: 0 24px;
  min-height: 36px;

  &:hover {
    color: $secondary-dark;
    background-color: $light-gray-10;
    text-decoration: none;
  }
}

@mixin nav-button {
  @include configure-button($primary: false, $style: 'textOnly', $small: false);

  &.isAnchor {
    text-decoration: none;
  }

  &.back {
    @include nav-back;
  }

  &.tab {
    @include nav-tab;
  }
}

@mixin refresh-button {
  @include base-btn;
}

/* ---------------VARIANTS-------------------*/
@mixin filled {
  background-color: $secondary-action;
  border-color: $secondary-action;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    &:not(.loading) {
      background-color: $secondary-dark;
      border-color: $secondary-dark;
      color: $white;
    }
    //Override bootstrap
    &:disabled {
      background-color: $secondary-disabled;
      border-color: $secondary-disabled;
      color: $white;
    }
  }

  &:disabled {
    background-color: $secondary-disabled;
    border-color: $secondary-disabled;
    color: $white;
  }
}

@mixin ghost {
  background: transparent;
  border-color: $white;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    &:not(.loading) {
      &:not(.selected) {
        background: rgba($white, 0.25);
        border-color: $white;
        color: $white;
      }
    }
    //Override bootstrap
    &:disabled {
      opacity: 0.25;
      background: transparent !important;
    }
  }

  &:disabled {
    opacity: 0.25;
    background: transparent !important;
  }
}
@mixin nav-back {
  margin-left: -24px;

  svg {
    margin-right: 10px;
    margin-left: -3px; //To align arrow to button edge
  }
}

@mixin nav-tab {
  padding: 0;
  margin: 0 18px;

  &.dashlet {
    color: $teal5;
    font-size: 18px;
    min-width: 25px;
  }

  &.muted {
    color: gray;
    font-size: 18px;
    &:hover {
      color: $brand;
    }
  }

  &.selected {
    &:hover {
      text-decoration: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 2px;
      left: 0;
      background-color: $brand;
    }

    &.dashlet:after {
      bottom: -2px;
      background-color: $teal5;
      border-radius: 5px;
      height: 4px;
    }
  }
}

@mixin loader-within-button($textHidingColor: transparent) {
  .loading-container {
    display: flex;
    color: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0;
    }
  }

  &.loading,
  &.loading:disabled {
    color: $textHidingColor;
  }
}

/* ---------------ANIMATIONS-------------------*/
$swift-out: cubic-bezier(0.36, 0, 0.2, 1);
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ---------------INPUTS------------------------*/

@mixin input-container {
  position: relative;
  margin-bottom: 30px;
}

//Text Inputs
@mixin input-field {
  @include paragraph;
  box-shadow: 0 0 0 1px $semi-dark-gray;
  background: $white;
  border-radius: 3px;
  border: none;
  height: 46px;
  padding: 0 15px;
  caret-color: $primary-action;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    outline:none;
    border: none;
    box-shadow: 0 0 0 2px $primary-action ;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $medium-gray;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $medium-gray;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $medium-gray;
  }

  &:-ms-input-placeholder { /* IE */
    color: $medium-gray;
  }

  &:-ms-input-placeholder { /* IE Edge */
    color: $medium-gray;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

@mixin input-field-validation {
  @include paragraph-small;
  display: flex;
  align-items: flex-end;
  color: $alert;
  line-height: 14px;
}

@mixin text-area {
  @include input-field;
  padding-top: 10px;
  resize: none;
}


@mixin field-label {
  @include paragraph-strong;
  margin-bottom: 0; //bootstrap override
  font-style: normal;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &.error {
    color: $alert;
  }

  &.warning {
    color: $warning;
  }

  .optional {
    @include paragraph-small-quiet;
    display: inline-block;
    padding: 0 5px;
    line-height: 23px;
    background-color: $light-gray-10;
    border-radius: 2px;
  }
}

@mixin field-sub-label {
  @include paragraph-small;
  color: $semi-dark-gray;

  &.error {
    color: $alert;
  }

  &.warning {
    color: $warning;
  }
}

@mixin checkbox($type: 'add', $darkBg: false) {
  appearance: none;
  cursor: pointer;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid $semi-dark-gray;
  margin: 2px 12px 0 0 !important; //override bootstrap

  &:focus {
    outline: none !important;
    background-color: $teal1;
    box-shadow: 0 0 0 4px $white, 0 0 0 6px $teal4;
  }

  &:focus:not(:focus-visible) {
    background-color: transparent;
    border: 1px solid $semi-dark-gray;
    box-shadow: none;

    &:checked {
      background-color: $primary-action;
    }
  }

  &:hover {
    border: 1px solid $primary-action;
  }

  &:checked {
    outline: none;
    border-color: $primary-action;
    background-color: $primary-action;

    &:after {
      content: '';
      position: absolute;
      height: 9px;
      width: 10px;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxMCA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0MiAoMzY3ODEpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkFydGJvYXJkPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFydGJvYXJkIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkNoZWNrZWQiIHBvaW50cz0iOC4zMzMyNSAwIDEwIDEuODQ5NTYxOTUgMy42Mjc1IDkgMy41Mjk1IDguOTE3MjA5MDEgMCA0Ljk0MTgzMzU0IDEuNjY2NzUgMy4wOTE5ODk5OSAzLjYyNzUgNS4zMDA1OTQ0OSI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
      top: 5px;
      left: 4px;
    }
  }

  &:disabled {
    border-color: $medium-gray;
    background: $medium-gray;
    cursor: not-allowed !important
  }

  @if $darkBg == true {
    background-color: $white;
    border-color: $white;

    &:focus {
      border-color: $light-gray-70;
    }

    &:checked {
      border-color: $white;
      background-color: $white;

      &:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTAgOSI+CiAgPHBvbHlnb24gZmlsbD0iIzY4QzVFOSIgZmlsbC1ydWxlPSJldmVub2RkIiBwb2ludHM9IjguMzMzIDAgMTAgMS44NSAzLjYyNyA5IDMuNTMgOC45MTcgMCA0Ljk0MiAxLjY2NyAzLjA5MiAzLjYyNyA1LjMwMSIvPgo8L3N2Zz4K");
      }
    }
  }

  @if $type == 'subtract' {
    &:checked {
      &:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 12px;
        border-radius: 2px;
        background: $primary-action;
        left: 4px;
        top: 9px
      }
    }
  }
}

@mixin checkbox-label {
  @include paragraph;
  margin: 0;
  //line-height: normal;
}

@mixin radio-button {
  @include paragraph;
  display: flex;
  align-items: center;
  margin: 5px 10px 5px 0;

  &:hover:not(.disabled):not(.read-only) input {
    border-color: $primary-action;
  }

  label {
    margin-bottom: 0;
  }

  input {
    appearance: none;
    border: none;
    outline: none;
    position: relative;
    margin: 0 12px 0 0;
    flex-shrink: 0;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid $semi-dark-gray;

    &:active {
      outline: none !important;
    }

    &:focus {
      background-color: $teal1;
      outline: none;
      @include focus-boxshadow;
    }

    &:focus:not(:focus-visible) {
      background: transparent;
      border: 1px solid $semi-dark-gray;
      box-shadow: none;
    }
  }

  &.selected input, input:checked {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      top: 5px;
      left: 5px;
      background: $primary-action;

      &:disabled {
        background: $medium-gray;
      }
    }

  }

  &.read-only {
    &.selected input, input:checked {
      &:after {
        background: $semi-dark-gray;
      }
    }
  }

  &:disabled {
    background-color: $white; // circle background
  }
}

@mixin radio-button-stroked {
  @include radio-button;
  cursor: pointer;
  border: 1px solid $semi-dark-gray;
  border-radius: 8px;
  padding: 11px 24px 11px 15px;
  margin: 0 10px 10px 0;
  width: 100%;

  @include breakpoint(tablet) {
    width: auto;
  }

  &:hover:not(.disabled):not(.read-only) {
    border-color: $primary-action;
  }

  &.selected {
    border-color: $primary-action;
    color: $primary-action;

    &.read-only {
      border-color: $semi-dark-gray;
      color: $soft-black;
    }
  }

  &.read-only {
    cursor: default;
  }

  &.disabled {
    border-color: $semi-dark-gray;
    color: $semi-dark-gray;
  }
}

@mixin radio-button-jumbo {
  @include radio-button;
  flex: 1;
  align-items: flex-start;
  background-color: $white;
  cursor: pointer;
  border: 1px solid $semi-dark-gray;
  border-radius: 3px;
  padding: 12px 24px 12px 12px;
  margin: 0 10px 10px 0;
  width: 100%;

  input {
    margin-top: 8px;
    background-color: $white;
  }

  &:hover {
    border-color: $primary-action;
  }

  @include breakpoint(tablet) {
    width: auto;
  }

  .text {
    @include paragraph-large-strong;
    margin: 0;
  }

  .option-description {
    @include paragraph;
    margin: 10px 0;
  }

  &.selected input, input:checked {
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center center;
    //TODO: This image needs to be replaced
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIj4KICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjhDNUU5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIxMi4xMzMgMSAzLjc4MyA4Ljc5MyAxIDYuMDEiLz4KPC9zdmc+Cg==);
    border: none;

    &:focus {
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIj4KICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjhDNUU5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIxMi4xMzMgMSAzLjc4MyA4Ljc5MyAxIDYuMDEiLz4KPC9zdmc+Cg==);
      border: none;
    }

    &:after {
      display: none;
    }
  }

  &.selected {
    border-color: $primary-action;
    background-color: $primary-action;

    .text, .option-description {
      color: $white;
    }
  }

  input {
    cursor: pointer;
  }
}

@mixin disabled-input {
  cursor: not-allowed !important;
  color: $semi-dark-gray;

  input, select, textarea {
    box-shadow: 0 0 0 1px $light-gray-100;
    background-color: $light-gray-10;
  }

  label, .field-label {
    color: $light-gray-100;
  }

  * {
    cursor: not-allowed !important;
  }
}

@mixin read-only-text {
  background-color: transparent;
  box-shadow: 0 0 0 1px transparent !important;
  cursor: default;
  padding: 0;
  resize: none;
}

@mixin focus-circle($size: 40px) {
  height: $size;
  width: $size;
  border-radius: $size / 2;
  border: 2px solid $teal4;
  background: transparent;
}

/* ---------------OTHER MIXINS------------------------*/

// -- For circular profile pic. Background-image is defined inline
@mixin avatar($radius) {
  height: $radius;
  width: $radius;
  background: $light-gray-40 no-repeat 50%;
  background-size: cover;
  border: 6px solid $white;
  box-shadow: 0 0 0 1px $light-gray-40;
  border-radius: $radius/2;
  overflow: hidden;

  object {
    width: 100%;
    height: 100%;
    font-size: $radius/3;
    text-transform: uppercase;
    color: $secondary-dark;
    font-weight: 600;
    line-height: $radius - 10px;
  }
}

@mixin responsive-horizontal-align { // Main content container
  width: 87%;
  margin: 0 auto;
  position: relative;
  max-width: 1040px;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin dropzone-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  svg {
    flex: 0 0 auto;
  }

  span {
    font-size: 13px;
    color: $medium-gray;
    flex: 0 1 auto;
    margin-top: 5px;
  }
}

@mixin providerCarousel {
  .additional-provider-card {
    padding: 2px 15px;
    margin-bottom: 15px;

    > div {
      padding-left: 10px;
      padding-right: 10px
    }
  }

  :global {
    .slick-track {
      display: flex;
    }

    .slick-slide {
      display: flex;

      > div {
        width: 100%;
      }
    }
  }
}

@mixin term-token {
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: $dark-gray;
  border: none;
  background: $light-gray-40;
  padding: 8px 25px 8px 10px;
  margin: 5px 5px 0px 0;
  position: relative;
  max-width: 100%;
  border-radius: 3px;
  cursor: default;

  &-disabled {
    background-color: #ddd;
    color: #888;
    pointer-events: none;
  }

  &-removeable {
    cursor: pointer;
    padding-right: 21px;
  }

  &-active {
    background-color: $primary-action;
    color: #fff;
    outline: none;
    text-decoration: none;
  }

  .close {
    padding: 0 7px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: inherit;
    opacity: 1;
    text-shadow: none;
    font-weight: normal;
    outline: 0;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}

@mixin items-list {
  $rounded: 12px;
  margin: 0 0 20px 0;
  padding: 0;
  border-radius: $rounded;
  border: 1px solid $light-gray-70;
  background-color: $white;

  li:first-of-type {
    border-top-left-radius: $rounded;
    border-top-right-radius: $rounded;
  }

  li:last-of-type {
    border: none;
    border-bottom-left-radius: $rounded;
    border-bottom-right-radius: $rounded;
  }
}

@mixin focus-boxshadow($innerBorder: $white, $outerBorder: $teal4 ) {
  box-shadow: 0 0 0 4px $innerBorder, 0 0 0 6px $outerBorder;
}

@mixin focus-style($background: $teal1, $innerBorder: $white, $outerBorder: $teal4 ) {
  &:focus {
    outline: 0;
		background: $background;
		border-color: $outerBorder;
		@include focus-boxshadow($innerBorder, $outerBorder);
	}

	// override focus styles when focus-visible is supported
  	// this sets it back to its non focus style
  	&:focus:not(:focus-visible) {
		background: none;
		border: none;
		box-shadow: none;
	}
}

@function fluidCalc($minSize, $maxSize, $minViewport, $maxViewport) { // without "px"
  // refernce: https://www.madebymike.com.au/writing/fluid-type-calc-examples/
  @return calc(#{$minSize}px + (#{$maxSize} - #{$minSize})*(100vw - #{$minViewport}px)/(#{$maxViewport} - #{$minViewport}))
}

@mixin medium-card-spacing() {
  max-width: 860px;
  margin: 50px auto 65px auto;
}

/* ---------------COLORS------------------------*/

// UI-Core related variables have been moved into the UI-Core library
// Project specific variables or overrides can still live below

// Any variables included here will:
// - override the corresponding UI-Core variable where there are overlaps
// - append to the list of UI-Core variables if its unique

/* ---------------BREAKPOINTS------------------------*/

// UI-Core related mixins have been moved into the UI-Core library
// Project specific mixins or overrides can still live below

// Any mixins included here will:
// - override the corresponding UI-Core mixin where there are overlaps
// - append to the list of UI-Core mixins if its unique
/* ---------------TYPOGRAPHY------------------------*/

// UI-Core related mixins have been moved into the UI-Core library
// Project specific mixins or overrides can still live below

// Any mixins included here will:
// - override the corresponding UI-Core mixin where there are overlaps
// - append to the list of UI-Core mixins if its unique
/* ---------------BUTTONS------------------------*/

// UI-Core related mixins have been moved into the UI-Core library
// Project specific mixins or overrides can still live below

// Any mixins included here will:
// - override the corresponding UI-Core mixin where there are overlaps
// - append to the list of UI-Core mixins if its unique
/* ---------------COMMON------------------------*/

// UI-Core related mixins have been moved into the UI-Core library
// Project specific mixins or overrides can still live below

// Any mixins included here will:
// - override the corresponding UI-Core mixin where there are overlaps
// - append to the list of UI-Core mixins if its unique

@mixin white-card {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 0 2px $light-gray-70;
  overflow: auto;
}

//TODO: Check if this is safe to remove
%standard-container {
  width: 87%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

@mixin recaptcha-legal {
  height: 45px;
  padding-left: 45px;
  background: url('https://www.gstatic.com/recaptcha/api2/logo_48.png') no-repeat left center/34px 34px;

  p {
    @include paragraph-small;
    margin: 0;
  }

  a {
    @include link;
  }
}

@mixin page-level-vertical-margin {
  margin: $medium 0;

  @include breakpoint(tablet) {
    margin: $jumbo 0;
  }
}

/* ---------------INPUTS------------------------*/

// UI-Core related mixins have been moved into the UI-Core library
// Project specific mixins or overrides can still live below

// Any mixins included here will:
// - override the corresponding UI-Core mixin where there are overlaps
// - append to the list of UI-Core mixins if its unique
/* ---------------OTHER MIXINS------------------------*/

// UI-Core related mixins have been moved into the UI-Core library
// Project specific mixins or overrides can still live below

// Any mixins included here will:
// - override the corresponding UI-Core mixin where there are overlaps
// - append to the list of UI-Core mixins if its unique

@mixin card-with-shadow {
  background-color: white;
  border-radius: $small;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

@mixin responsive-container {
  @include breakpoint(tablet) {
    width: 720px;
  }

  @include breakpoint(laptop) {
    width: 864px;
  }

  @include breakpoint(desktop-l) {
    width: 944px;
  }
}

.authentication-banner, .registration-banner {
  margin-bottom: 0;
  order: -1;
}

.eligibility-banner, .profile-banner {
  margin-bottom: 0;
}
