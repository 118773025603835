@import "_variables.scss";
@import "_mixins.scss";

/****************************************************

=== ALL STYLES RELATED TO MARGINS AND SPACING BETWEEN
     THE DIFFERENT CONTAINERS IN THE PAGE
     E.G. LEFT AND RIGHT COLUMN CARDS.

******************************************************/

// To align on left and right side of page centering the element
// use in container divs to have them aligned with header and footer
.responsive-horizontal-align {
  width: 87%;
  margin: 0 auto;
  float: none;
  position: relative;
  max-width: 1040px;
}

// remove left and right margin THIS IS FOR APPLYING with .row CLASS IN THE SAME ELEMENT
.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// to center container columns that have less content than other columns in grid. Use with .row bootstrap class
.vertical-align {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

// add height to content wrapper to create sticky footer effect
.common-content-container {
  min-height: calc(100vh - 380px);
  overflow: visible;

  @include breakpoint(tablet) {
    min-height: calc(100vh - 206px);
  }
}

/****************************************************
=== START STYLES TO APPLY TO GRIDS WHOSE
    CHILD ELEMENTS NEED TO HAVE SAME HEIGHT
******************************************************/

.equal-height-elem-wrapper { // add this to container
  justify-content: space-between;

  &.equal-height-responsive {
    display: block;  // stack cards on mobile and laptop

    @include breakpoint(tablet) {
      display: flex; // cards in same row
    }
  }

  &.equalHMWrap {
    justify-content: space-between;
  }

  .equal-3-child-row { // TODO: add this for 3 cards per row, create a new class when needed to fit more elems in same row
    width: 100%;

    @include breakpoint(tablet) {
      width: 32%;
    }
  }

  .equal-2-thirds-child-row { // TODO: add this for 3 cards per row, create a new class when needed to fit more elems in same row
    width: 100%;

    @include breakpoint(tablet) {
      width: 32%;
      &:nth-of-type(2){
        width: 62%;
      }
    }
  }
}


/****************************************************
=== START STYLES FOR ALL ON BOARDING SCREENS
******************************************************/

.onboard-left-col {

  .white-card {
    //overflow: auto;   causes scroll bars in IE11
    padding: 25px 20px 45px;

    @include breakpoint(tablet) {
      padding: 50px 9%;
    }
  }

  .sub-heading {
    margin: 15px 0 40px;
  }
}

.onboard-right-col {
  margin-top: 50px;

  @include breakpoint(laptop) {
    padding-left: 4px;
    margin-top: 0;
  }

  .contact-care-advisor-panel-title {
    margin-top: 45px;

    @include breakpoint(laptop) {
      margin-top: 0;
    }
  }

  .contact-care-advisor-panel-icon {
    width: 47px;
    top: -33px;
    left: 42%;

    @include breakpoint(laptop) {
      width: 47px;
      position: relative;
      top: 0;
      left: 7px;
    }
  }
}

/****************************************************
=== START STYLES FOR EXTERNAL COMPONENTS
******************************************************/

// Hide the widget for ICAS chat
#zsiq_float {
  visibility: hidden;
}