@import "variables";
@import "mixins";
@import "fonts";

@import "../../../src/styles/base/overrides/0colors";
@import "../../../src/styles/base/overrides/2typography";


// TODO: always wrap form inputs in a div and add this class to it:
.form-field-box {

  &.error{
    border: 1px solid $alert-100;
    border-radius: 5px;
  }
  position: relative;

  input[type=text],
  input[type=password],
  textarea {
    width: 100%; // DO NOT REMOVE, default width of inputs is the width of its parent
  }
}

.global-form-item-border { //DO NOT USE THIS IN HTML
  @include border-radius(3px);
  border: 1px solid $gray-30;
}


/****************************************************
=== START STYLES FOR INPUTS
******************************************************/
.lyra-input {
  @include input-field;

  &.border-invalid {
    box-shadow: 0 0 0 1px $alert;
  }

  &.success {
    border-color: $success;
  }
}

.lyra-label {
  @include field-label;
}

.form-item-label {
  @include field-label;
  margin-bottom: 8px !important;
}

.bold {
  color: $gray-75;
  font-weight: 600;
}
/****************************************************
=== START STYLES FOR  CHECK BOX
******************************************************/
.form-checkbox {
  display: flex;
  align-items: center;

  input {
    @include checkbox;
    flex-shrink: 0;
  }

  label {
    @include paragraph;
    margin: 0;
  }
}

/****************************************************
=== START STYLES FOR  RADIO BUTTON
******************************************************/

.form-radio {
  @extend .global-form-item-border;
  @include border-radius(10px);
  display: inline-block;
  margin: 0 8px -5px 0;
  width: 20px;
  height: 20px;
  background: $white;

  &.selected-true {
    border: 6px solid $secondary-action;
    display: inline-flex;
  }
}

/****************************************************
=== START STYLES FOR  TEXTAREA
******************************************************/
/* This global style is dangerous. Though removing it might introduce regresssions
textarea {
  @extend .global-form-item-border;
  min-height: 70px;
  padding: 15px 20px;
  outline: none;

  &:focus {
    border: 1px solid $secondary-action;
    box-shadow: none;
  }
}
*/