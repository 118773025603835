@import "variables";
@import "mixins";
@import "fonts";

/****************************************************
=== START STYLES FOR ALL MODALS
******************************************************/
.modal-close-button {
  background: $white;
}

.no-scroll{
  position:fixed;
}
.lyra-modal-background {
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $black-50;
  z-index: 99999;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;

  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: none;

  &.open{
    pointer-events: auto;
    display: block;
  }
}

.lyra-modal-content {
  @include border-radius(5px);
  width: 90%;
  max-width: 945px;
  margin: 10% auto;
  position: relative;
  background: $white;
  border: none;

  @include breakpoint(tablet) {
    width: 80%;
    margin: 70px auto 40px;
  }
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px;
  line-height: 0;
  cursor: pointer;
}

.notice-container {
  height:80vh;
  overflow:scroll;
  padding:50px;
}

//Sizes
.modal-container-medium {
  max-width: 642px;
}



.common-modal {
  padding: 40px 6%;

  @include breakpoint(tablet) {
    padding: 50px 6%;
  }

  &.provider-detail-modal {
    padding: 40px 0;
    height: 100%;
    overflow: auto;
  }

  .contact-us-heading {
    width: 100%;
    margin: 0 auto;

    @include breakpoint(tablet) {
      width: 80%;
    }

    @include breakpoint(laptop) {
      width: 85%;
    }
  }

  .action-buttons-container {
    margin-top: 39px;
  }

  + .modal-footer { // for this modal, the close button at the bottom should not be displayed
    display: none;
  }
}

.contact-intro {
  text-align: center;
  line-height: 24px;
}

.contact-title {
  font-size: 26px;
  margin-bottom: 8px;
}

.contact-subtitle {
  font-size: 16px;
  font-weight: 300;
  color: $gray-75;
  margin: 17px 0 28px 0;
}

// ====> Form fields and text area section
.contact-method-box {
  &.email-row {
    margin-bottom: 10px;
  }

  .small-column {
    padding-right: 0;
  }
}

.compact-fields-wrapper {
  position:relative;
}

.validation-messages {
  @include paragraph-extra-small-strong;
  color: $light-gray-70;
  position: relative;
  margin-bottom: 0;

  &.error {
    color: $alert-100;
  }
}

.no-col-padding {
  padding-left: 0;
  padding-right: 0;
}

.cancel-btn {
  font-size: 12px;
  letter-spacing: 1px;
  color: $gray-35;
  width: 70px;
  display: inline-block;
  margin-left: 25px;
  height: 47px;
  line-height: 46px;
  vertical-align: bottom;
}

.text-area-notes {
  font-size: 15px;
}

// ------------ contact us right column: contact info
.contact-care-advisor-panel-container-passive {
  @include border-radius(4px);
  background: $white;
  box-shadow: 0 1px 4px 0 $gray-30;
  padding: 10px 10% 50px;

  &.blue-advisor-panel{
    background: darken($gray-25, 4%) !important;
    margin-left:20px;
    margin-top:25px;
  }
}


.contact-care-advisor-panel-icon-container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transform: translateY(-65%);
}

.contact-care-advisor-panel-icon {
  height: auto;
  width: 22px;
  position: absolute;
  top: 27px;

  @include breakpoint(laptop) {
    width: 47px;
    position: relative;
    top: 0;
  }
}

.contact-care-advisor-panel-title {
  font-size: 18px;
  font-weight: 600;
  color: $gray-75;
  text-align: center;
  line-height: 26px;
}

.contact-care-advisor-panel-content-paragraph {
  color: $gray-75;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;

  b {
    color: $orange-70;
  }
}

.contact-care-advisor-panel-phone {
  font-size: 16px;
  font-weight: 500;
  color: $gray-75;
  text-align: center;
}

.contact-care-advisor-panel-phone-icon,
.contact-care-advisor-panel-chat-icon {
  color: rgba(74,74,74,0.35);
}

.contact-care-advisor-panel-chat {
  font-size: large;
  font-weight: bold;
  color: $secondary-action;
  text-align: center;
}


/****************************************************
=== START STYLES FOR QUICKBOOK MODAL
******************************************************/

.quickbook-modal {
  max-width: 425px;
  padding: 37px 15px 25px 15px;
  @include breakpoint(tablet) {
    padding: 37px 45px 25px 45px;
  }
}

.quickbook-modal-content {
  text-align: center;

  .quickbook-title {
    @include header4-quiet;
    margin: 0;
  }

  //Begin top section
  .appointment-details {
    $pic-radius: 66px;
    padding: 20px 0 30px 0;
    min-height: $pic-radius;
    border-bottom: 2px solid $gray-27;

    .provider-pic-container {
      margin: 0 auto;
    }

    .provider-name {
      margin: 10px 0;
    }

    .when-contact-text {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      color: $gray-75;
    }

    .appointment-time {
      @include paragraph-small-strong;
      margin-bottom: 3px;
    }

    .appointment-day, .location-address {
      @include paragraph-small;
      line-height: 18px;
    }
  }

  .change-contact-greeting {
    padding: 30px 0 0 0;
    font-size: 13px;
    color: $gray-100;
    font-weight: 600;
    line-height: 18px;
  }

  //Begin bottom section
  .appointment-inputs {
    padding: 30px 0 0 0;

    .question {
      font-size: 13px;
      color: $gray-75;
      margin-bottom: 10px;
    }

    .session-type {
      @include header4;
      margin-bottom: 15px;

      &.contact {
        @include paragraph-small;
      }

      &.video:before, &.in-person:before {
        content: '';
        display: inline-block;
      }

      &.video:before {
        background: url('/images/onboard/live-video-icon.png') no-repeat;
        width: 19px;
        height: 14px;
        margin: 4px 12px 0 0;
      }

      &.in-person:before {
        background: url('/images/onboard/in-person-icon.png') no-repeat;
        width: 23px;
        height: 18px;
        margin: 6px 12px 0 0;
      }
    }

    .person-or-video, .phone-input {
      margin-bottom: 25px;
    }

    .phone-or-email {
      margin-bottom: 15px;
    }


    .phone-input input {
      text-align: center;
    }
  }

  button {
    &.primary-btn {
      display: block;
      padding-right: 60px;
      padding-left: 60px;
      margin: 10px auto 20px auto;
    }
  }
}

/****************************************************
=== START STYLES FOR VIDEO MODAL
******************************************************/
.video-player {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(9 / 16 * 100%); // Maintains 16:9 aspect ratio
}

.video-player iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.messages-popover-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;

  @include breakpoint(tablet) {
    display: none;
  }
}

.messages-popover-modal {
  @include breakpoint(tablet) {
    position: relative;
  }

  .messenger-container {
    background-color: $white;
    flex-grow: 1;
    max-width: 520px;
    width: 100%;
    display: flex;

    &.hide {
      display: none;
    }
  }

  &.large .messenger-container {
    width: 100%;

    @include breakpoint(laptop) {
      width: initial;
    }
  }

  .none-selected {
    @include paragraph;
    text-align: center;
    margin-top: 60px;
  }

  >div {
    display: flex;
    position: absolute;
    z-index: 999;
    top: $small;
    left: 0;
    right: 0;
    height: 75vh;
    width: calc(100vw - 32px);
    max-width: 520px;
    max-height: 810px;
    border-radius: 12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(tablet) {
      top: $tiny;
      right: -15px;
      left: initial;
    }

    @include breakpoint(mobileonly) {
      position: fixed;
      height: 95vh;
      max-height: unset;
    }

    .provider-list {
      overflow-y: auto;
      height: 100%;
      background-color: $white;
      border-right: 1px solid $light-gray-70;
      width: 100%;

      @include breakpoint(laptop) {
        width: calc((240 / 760) * 100%);
      }

      .top-bar {
        min-height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $light-gray-70;
        padding: 0 20px;

        .list-title {
          @include paragraph-large-strong;
        }

        .close-icon {
          cursor: pointer;
        }

        @include breakpoint(laptop) {
          display: none;
        }
      }

      .list-chevron {
        @include breakpoint(laptop) {
          display: none;
        }
      }

      &.hide {
        display: none;
      }

      .list-item {
        margin: 0;
        border: none;
        width: 100%;
        background-color: $white;
        height: 90px;
        padding: $small;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px solid $light-gray-70;

        &:focus {
          outline: 0;
          box-shadow: inset 0 0 0 2px $teal4;
        }

        .content-container {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .header {
          @include header4;
          font-size: 16px; // FIX: Not a ui-core type style
          margin: 0 13px;
        }

        .unread-badge {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          margin-left: auto;
          background-color: $alert;
          color: $white;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: $bold;
        }

        &.selected {
          background-color: $teal1;
        }
      }
    }
  }

  // Used when the popover also contains the provider list
  &.large>div {
    @include breakpoint(laptop) {
      max-width: 760px;
    }
  }

  &.session-banner>div {
    // Push messenger up on mobile when banner is visible (16px - 90px)
    top: -74px;

    @include breakpoint(tablet) {
      top: -45px;
    }

    // Display messenger below session banner
    @include breakpoint(laptop) {
      top: 126px;
      height: calc(85vh - 90px);
    }
  }
}
