@import "_variables.scss";
@import "_mixins.scss";
@import "../../../src/styles/base/overrides/0colors";
@import "../../../src/styles/base/overrides/2typography";

/****************************************************************************
=== TYPOGRAPHY, H TAGS, FONT SIZES, TEXT STYLES PER DESIGN STYLE GUIDE
*****************************************************************************/

b {
  font-weight: $semibold;
}

// per Style guide:
h1, .h1-text {
  @include header1;
}

h2, .h2-text {
  @include header2;
}

h3, .h3-text {
  @include header3;
}

h4, .h4-text {
  @include header4
}

h5, .h5-text {
  @include header5;
}

p, .paragraph-text {
  @include paragraph;
}

.foot-note {
  @include footnote;
}

/****************************************************
=== START STYLES FOR HEADERS FROM ON BOARDING FLOW
******************************************************/

.page-heading { // in on boarding, where copy is loaded dynamically
  @include header3;
  margin-bottom: 24px;
}

.sub-heading {
  @include paragraph;
  margin-top: 5px;
  margin-bottom: 25px;
}

/****************************************************
=== START STYLES FOR CARE ADVISOR BLUE SECTION HEADERS
******************************************************/
.make-font-blue {
  color: $secondary-dark;
}