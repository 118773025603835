/* ---------------COLORS------------------------*/

// PLEASE NOTE: THE HIGHER THE NUMBER IN THE VARIABLE, THE DARKER THE COLOR.
// TODO: if design shows a color that is not listed here, please use the variable closest to that color

$black-100: #000;
$black-50: rgba(#41444e, .80);

$gray-100: rgba(#4a4a4a, 1); // darkest gray, used in large fonts
$gray-75: rgba(#4a4a4a, .75); // labels and paragraphs
$gray-50: rgba(#4a4a4a, .5); // labels and paragraphs
$gray-35: rgba(#4a4a4a, .35); // for the foot notes
$gray-30: #dbdbdb; // disabled buttons
$gray-27: #f3f3f1; // for inputs and dropdown borders
$gray-25: #f8f8f8; // for dropdown backgrounds AND page background
$gray-15: #FBFBFB;

$purple-100: #6a5879; // for the buttons on hover
$purple-80: #7e6990; // for the buttons on hover
$purple-70: #beb4c7; // for secondary button active
$purple-60: #8b789b; // the right side of the button menu

$blue-300: #2a4c71;
$blue-250: #476d96;
$blue-200: rgba(#476D96, .75) ;
$blue-100: #62bad9;

$alert-100: #ce4e5a;
$alert-80: #e96875;

$green-100: #56b68b;

$orange-70: #FF9E47;
