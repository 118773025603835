@import "variables";
@import "mixins";
@import "fonts";
// >>>>>>>>>> BELOW CLASS IS NOT TO  BE USED IN HTML
.global-dropdown {//  this should be included in styles for a ul element
  @include border-radius(0 0 3px 3px);
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: 1px solid $gray-30;
  width: 100%;

  li {
    list-style-type: none;
    padding: 12px 10px;
    background: $gray-25;
    cursor: pointer;
    border: none; // override default borders first
    border-bottom: 1px solid $gray-30;

    &:last-of-type {
      @include border-radius(0 0 3px 3px);
      border-bottom: none;
    }

    @include breakpoint(laptop) {
      &:hover {
        background: $white;
        color: $secondary-action;
      }
    }
  }
}

// -- Primay and secondary buttons when disabled

.button-disabled,
button[disabled] {
  cursor: default;
  color: $white;
  background: $gray-30;
  border: none;

  &:hover {
    cursor: default;
    background: $gray-30;
    border: none;
  }
}

/****************************************************
=== START STYLES FOR PRIMARY BUTTON
******************************************************/

.primary-btn {
  @include primary-button;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

.align-button-left {
  margin-left: -30px;
}

/****************************************************
=== START STYLES FOR SECONDARY BUTTON
******************************************************/

.secondary-btn {
  @include secondary-button;
}

.secondary-btn.gray {
  color: $gray-75;
  border: 1px solid $gray-30;

  &:hover,
  &:active,
  &:focus {
    background: $white;
    color: $gray-100;
    border: 1px solid $gray-50;
    box-shadow: none;
  }
}

/****************************************************
=== START STYLES FOR PRIMARY BUTTON TEXT ONLY
******************************************************/

.primary-btn.text-only {
  background: none;
  border: none;
  color: $primary-action;
  padding: 17px 25px 16px 25px;

  @include breakpoint(laptop) {  // HOVER EVENTS ALWAYS MUST BE EXCLUDED FROM MOBILE DEVICES
    &:hover,
    &:active,
    &:focus {
      background: none;
      color: $primary-highlight;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    background: url("/images/purple_arrow_right.png") no-repeat;
    background-size: 5px 8px;
    width: 5px;
    height: 8px;
    margin-left: 12px;
    margin-bottom: 1px;

  }

  &:disabled, &.button-disabled {
    color: $gray-30;
    &:after {
      background: url("/images/gray_arrow_right.png") no-repeat;
    }
    &:hover {
      color: $gray-30;
    }
  }
}

.primary-btn.left {
  background: none;
  border: none;
  color: $primary-action;
  padding: 16px 15px;
  //margin-left: -30px;

  @include breakpoint(laptop) {  // HOVER EVENTS ALWAYS MUST BE EXCLUDED FROM MOBILE DEVICES
    &:hover,
    &:active,
    &:focus {
      background: none;
      color: $primary-highlight;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    background: url("/images/purple_arrow_left.png") no-repeat;
    background-size: 5px 8px;
    width: 5px;
    height: 8px;
    margin-right: 12px;
    margin-bottom: 1px;

  }

  &:disabled, &.button-disabled {
    color: $gray-30;
    &:before {
      background: url("/images/purple_arrow_left.png") no-repeat;
    }
    &:hover {
      color: $gray-30;
    }
  }
}

/****************************************************
=== START STYLES FOR SECONDARY BUTTON TEXT ONLY
******************************************************/

.secondary-btn.open-modal {

  @include breakpoint(laptop) {  // HOVER EVENTS ALWAYS MUST BE EXCLUDED FROM MOBILE DEVICES
    &:hover,
    &:active,
    &:focus {
      background: none;
      color: $gray-100;
    }
  }

  @include breakpoint (desktop) {
    border: none;
    background: none;
    padding: 0;
  }

  &:after {
    content: '';
    display: inline-block;
    background: url("/images/purple_arrow_right.png") no-repeat;
    background-size: 5px 8px;
    width: 5px;
    height: 8px;
    margin-left: 12px;
    margin-bottom: 1px;

  }

  &:disabled, &.button-disabled {
    color: $gray-30;
    &:after {
      background: url("/images/gray_arrow_right.png") no-repeat;
    }
    &:hover {
      color: $gray-30;
    }
  }
}

/****************************************************
=== START STYLES FOR SECONDARY BUTTON TEXT ONLY
*****************************************************/

.secondary-btn.text-only {
  background: none;
  border: none;
  color: $gray-35;
  padding: 0;

  @include breakpoint(laptop) {  // HOVER EVENTS ALWAYS MUST BE EXCLUDED FROM MOBILE DEVICES
    &:hover,
    &:active,
    &:focus {
      background: none;
      color: $gray-100;
    }
  }

  &:disabled, &.button-disabled {
    color: $gray-30;
    &:hover {
      color: $gray-30;
    }
  }
}


/****************************************************
=== START STYLES FOR ADD TO CALENDAR BUTTON
*****************************************************/

.primary-btn.add-to-calendar-solid {

  @include breakpoint(laptop) {  // HOVER EVENTS ALWAYS MUST BE EXCLUDED FROM MOBILE DEVICES
    &:hover,
    &:active,
    &:focus {
      background: $primary-highlight;
      color: $white;
    }
  }

  &:after {
    content:none;
  }

  &:before {
    content: '';
    display: inline-block;
    background: url("/images/white_calendar_icon.png") no-repeat;
    background-size: 15px 14px;
    width: 15px;
    height: 14px;
    margin-right: 12px;
    margin-bottom: -3px;

  }

  &:disabled, &.button-disabled {
    &:after {
      content: none;
    }
    &:before {
      background: url("/images/light_gray_calendar_icon.png") no-repeat;
    }
  }
}

/***************************************************
=== START STYLES FOR ADD TO CALENDAR BUTTON BORDER
******************************************************/

.primary-btn.add-to-calendar {
  @extend .secondary-btn;

  &:before {
    content: '';
    display: inline-block;
    background: url("/images/purple_calendar_icon.png") no-repeat;
    background-size: 15px 14px;
    width: 15px;
    height: 14px;
    margin-right: 12px;
    margin-bottom: -3px;

  }

  &:disabled, &.button-disabled {
    &:before {
      background: url("/images/light_gray_calendar_icon.png") no-repeat;
    }
  }
}

/****************************************************
=== START STYLES FOR DROP-DOWNS
******************************************************/

.dropdown-box { // ALWAYS wrap the dropdown with a div and add this class, otherwise menu will appear at the bottom of page
  position: relative;

  &.header-onboard-dropdown{
    display: inline-block;
    margin-right: 120px;
    position: relative;
    top: 16px;
  }

  &.open { // STYLE HERE ANY CONTACT_US_TYPE OF DROP-DOWN ON OPEN MODE

    .caret {
      transform: rotate(180deg);
    }

    .primary-dropdown {
      @include border-radius(8px 8px 0 0);
      .caret-box {
        background: $primary-light;
        @include border-radius(0 8px 0 0);
      }
    }

    .form-dropdown {
      @include border-radius(3px 3px 0 0);
      border-bottom-color: transparent;
      .caret {
        color: $secondary-action;
      }
    }
  }

  .dropdown-menu {  // ALL GLOBAL MENU LISTS STYLES
    @extend .global-dropdown;
  }

  .caret { // ALL GLOBAL CARET
    border-top: 6px dashed $primary-action;
    border-top: 6px solid\9;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    margin-left: 0;
    margin-top: 22px;
  }

  /****************************************************
  === START STYLES FOR BUTTON WITH MENU
  ******************************************************/

  .primary-dropdown {
    @extend .primary-btn;
    min-width: 160px;
    min-height: 42px;
    position: relative;
    padding-right: 40px;
    width: 100%;

    .button-text {
      text-align: left;
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      padding: 0 30px 0 0;
    }

    .caret-box {
      @include border-radius(0 8px 8px 0);
      display: inline-block;
      height: 100%;
      width: 40px;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid $white;
      background: $primary-light;
      color: $white;
    }
  }

  /****************************************************
  === START STYLES FOR DROP DOWN WITH DIFFERENT CARET STYLE
  ******************************************************/
  .small-caret {
    font-size: 12px;

    .caret-box {
      display: none;
    }

    .button-text {
      width: auto;

      &:after {
        display: inline-block;
        content: '';
        height: 5px;
        width: 8px;
        margin-left: 8px;
        margin-bottom: 1px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAABGdBTUEAALGPC/xhBQAAAFlJREFUCB1j/P//vzkDAwMLEJ9hZGT8CaQZgGLsQMoEiP8wAQmQpBAQW4IkoJKWUDEWRiQBXqDgZyAGARj7OCOIh6YIJARSeBxkJVgBSASqyAzI/AfEcPcAAP/BIeXper6TAAAAAElFTkSuQmCC);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

  }

  /****************************************************
    === START STYLES FOR DEFAULT FORM DROP-DOWN
  ******************************************************/

  .form-dropdown {
    @include border-radius(3px);
    background: $white;
    padding: 14px 30px 14px 15px;
    border: 1px solid $gray-30;
    position: relative;
    min-width: 160px;

    .button-text {
      text-align: left;
      font-size: 15px;
      display: inline-block;
      width: 100%;
      color: $gray-50;
      white-space: nowrap;
      padding: 0 30px 0 0;
    }

    .caret-box {
      @include border-radius(0 3px 3px 0);
      display: inline-block;
      height: 48px;
      width: 40px;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid $white;
      background: $white;
      color: $gray-30;
    }
  }
}


/****************************************************
=== START STYLES FOR RADIOS / CHECK BOXES
******************************************************/

.radio-button {
  @include border-radius(10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid $gray-35;
}

.check-box {
  @include border-radius(3px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid $gray-35;
}

/****************************************************
=== START STYLES FOR TOGGLE BUTTTONS
******************************************************/
.toggle-container {
  display: flex;
  justify-content: center;
}

/**********************************************************
=== START STYLES FOR BUTTONS USED ON SINGLE SELECTION LISTS
***********************************************************/
.radio-boxes-wrapper {
  margin: -5px 0;
}

.single-select-button { // parent container
  @include box-shadow(0px 1px 2px 0px $gray-30);
  @include border-radius(3px);
  position:relative;
  border: 1px solid $gray-30;
  margin: 5px 0 0;
  padding: 5px 0;
  cursor: pointer; //to indicate clicking anywhere selects the card

  @include breakpoint(tablet) {
    &:nth-child(odd) {
      margin-right: 10px;
    }

    width: calc(50% - 5px);
    display: inline-block;
  }

  &.selected-true {
    @include box-shadow(0 0 4px 2px $gray-30);
    border: 1px solid $secondary-action;

    .radio-button {
      border: 5px solid $secondary-action;
    }
  }

  .radio-card-content {
    position:relative;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);

    h5 {
      line-height:20px;
      color: $gray-75;
      font-size: 12px;
      letter-spacing: 0;
    }

    .radio-button {
      margin-top: 9px;
    }

    .col-xs-2 {
      width: 20px;
      margin: 0 10px;
      padding: 0;
    }

    .col-xs-10 {
      padding: 0 10px 0 0;
      width: calc(100% - 40px);
    }
  }
}

/****************************************************************
=== START STYLES FOR THE MULTI SELECT BUTTONS (WORD GRID BUTTONS)
****************************************************************/
.word-box {
  margin: 0 10px 10px 0;
  overflow: hidden;
  float: left;
  position: relative;
  -moz-flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;

  label {
    float: left;
    width: 100%;
    margin: 0;

    input {
      position: absolute;
      top: -20px;

      &:checked+span {
        background-color: $secondary-action;
        border: 1px solid $secondary-action;
        color: $white;
      }
    }

    span {
      @include border-radius(4px);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid $gray-30;
      display: inline-block;
      cursor: pointer;
      width: 100%;
      background: $gray-25;
      font-size: 12px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-weight: 400;
      color: $gray-75;
      text-transform: uppercase;

      &:hover {
        background: $white;
        border: 1px solid $secondary-action;
        color: $secondary-action;
      }
    }
  }
}

/****************************************************
=== START STYLES FOR CURRENT LOCATION BUTTON
******************************************************/
.current-location-btn {
  @include border-radius(3px);
  width: 38px;
  height: 45px;
  vertical-align: top;
  background-color: $white;
  border: 1px solid $gray-30;
  margin-left: 2px;

  &:hover {
    background-color: $secondary-action;
    color: $white;
    border: 1px solid $secondary-action;
  }

  &.disabled {
    border: 1px solid $gray-30;
    background: $white;
    color: $gray-25;

    &:hover {
      border: 1px solid $gray-30;
      background: $white;

    }
  }
}

/****************************************************
=== START STYLES FOR GENDER BUTTONS
******************************************************/
.gender-selection-container {
  .gender-button {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    cursor: pointer;

    @include breakpoint(tablet) {
      height: 72px;
      line-height: 72px;
    }

    &:last-of-type {// female button add more space between buttons for iPad and up

      @include breakpoint(tablet) {
        padding-left: 21px;
      }

      .gender-text {// for mobile this prevents female button from wrapping
        padding-right: 0;
      }
    }

    span {
      display: inline-block;
    }

    .icon-female {
      background: url('/images/onboard/cm-profile-female.png') no-repeat top left / 99% 99% border-box content-box;

      &.selected-true {
        background: url('/images/onboard/female-blue.png') no-repeat top left / 99% 99% border-box content-box;
      }
    }

    .icon-male {
      background: url('/images/onboard/cm-profile-male.png') no-repeat top left / 99% 99% border-box content-box;

      &.selected-true {
        background: url('/images/onboard/male-blue.png') no-repeat top left / 99% 99% border-box content-box;
      }
    }

    .gender-text {
      padding: 0 15px;
      color: $gray-35;
      font-size: 15px;
    }

    .icon-box {
      height: 50px;
      width: 50px;

      @include breakpoint(tablet) {
        height: 72px;
        width: 72px;
      }
    }
  }
}

/****************************************************
=== START STYLES FOR TYPE AHEAD MENUS
******************************************************/
.lyra-typeahead {
  input {
    width: 100%;
  }

  .typeahead-menu {
    @extend .global-dropdown;
    border-top: none;

    &.no-display {
      display: none;
    }
  }
  .lyra-dropdown-option {

    &:first-of-type {
      borer-top: none;
    }

    &.lyra-dropdown-option-active {
      background: $white;
      color: $secondary-action;
    }
  }

}

/****************************************************
=== START STYLES FOR READ MORE
******************************************************/
.read-more {
  text-transform: uppercase;
  color: $primary-action;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1px;

  &:hover, &:active {
    cursor: pointer;
    color: $primary-highlight;
    text-decoration: none;
  }
}
