// -- BROWSER COMPATIBILITY FOR BORDER-RADIUS
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// -- BOX SHADOW

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  box-shadow: $shadow;
}

// -- BOX SIZING

@mixin box-sizing($box-model) {
  -webkit-box-sizing:$box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

// -- FLEX BOX
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// -- For circular profile pic. Background-image is defined inline
@mixin avatar($radius) {
  height: $radius;
  width: $radius;
  background: $gray-15 no-repeat 50%;
  background-size: cover;
  border: 6px solid $white;
  @include box-shadow(0px 0px 0px 1px $gray-27);
  border-radius: $radius/2;
  overflow: hidden;

  object {
    width: 100%;
    height: 100%;
    font-size: $radius/3;
    text-transform: uppercase;
    color: $secondary-dark;
    font-weight: 600;
    line-height: $radius - 10px;
  }
}

// -- VERTICAL GRADIENT
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

// -- HORIZONTAL GRADIENT
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Prefixes for transition
@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

// Prefixes for transform
@mixin transform($transforms) {
-moz-transform: $transforms;
-o-transform: $transforms;
-ms-transform: $transforms;
-webkit-transform: $transforms;
transform: $transforms;
}

/****************************************************
=== START ALL MEDIA QUERIES
******************************************************/

@mixin breakpoint($point) {

  @if $point == mobileonly {
    @media (max-width: 767px) { @content ; }
  }

  @else if $point == tablet {
    @media (min-width: 768px) { @content ; }
  }

  @else if $point == laptop {
    @media (min-width: 992px) { @content ; }
  }

  @else if $point == desktop {
    @media (min-width: 1200px)  { @content ; }
  }
}
