@import "_variables.scss";
@import "_mixins.scss";

/****************************************************

=== DEFAULTS FOR ALL HTML TAGS REMOVING
    BROWSER GIVEN STYLES.
=== UTILITY CLASSES TO APPLY DIFFERENT TO ELEMENTS FOR
    LAYOUT

******************************************************/

html, body {
  min-height: 100%;
}

body, label, textarea {
  @include primary-font
}

// to create the white container with shadow and round corners
.white-card {
  @include base-white-card;
  min-height: 200px;
}


// TODO: to be used with buttons, form elements and any tag that needs to be 100% its parent's width
.go-full-width {
  width: 100%;
}

//Apply to empty div to create a horizontal divider
.horizontal-line {
  height: 2px;
  width: 100%;
  background-color: $gray-27;
}

//2 column list
.two-col-list {
  @include breakpoint(tablet) {
    -moz-column-count: 2;
    -moz-column-gap: 35px;
    -webkit-column-count: 2;
    -webkit-column-gap: 35px;
     column-count: 2;
     column-gap: 35px;
  }

}
